import styled from "styled-components";

export const Screen = styled.div`
  font-family: 'Lora', serif;
  background-color: #fff;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  min-height: 100vh;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
`;

// navBar
export const NavBar = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 48px;
`;

export const Logo = styled.p`
  font-size: 22px;
  padding-top: 16px;
  padding-left: 16px;
  text-align: left;
`;

// profile
export const ProfileArea = styled.p`
  padding:0px 24px;
  color: #4d4d4d;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  text-align: left;
  max-width: 640px,;
  margin: 0 auto;
`;

// worksArea
export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 0px) {
    flex-direction: row;
  }
`;

export const PortfolioCard = styled.div`
  margin: 3px;
  max-width: 120px;
  max-height: 120px;
  @media (min-width: 500px) {
    max-width: 135px;
  max-height: 135px;
  }
  @media (max-width: 405px) {
    max-width: 100px;
  max-height: 100px;
  }
  @media (min-width: 585px) {
    max-width: 165px;
  max-height: 165px;
  }
  @media (min-width: 620px) {
    max-width: 180px;
  max-height: 180px;
  }
  @media (max-width: 345px) {
    max-width: 90px;
  max-height: 90px;
  }
`;

export const CardHover = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  font-size: 13px;
  color: #fff;
  padding: 64px 8px;
  fontWeight: bold;
  @media (max-width: 405px) {
    max-width: 100px;
    max-height: 100px;
    padding: 58px 4px;
    font-size: 11px;
  }
  @media (min-width: 500px) {
    padding: 60px 4px;
    font-size:12px;
  }
  @media (min-width: 585px) {
    max-width: 165px;
    max-height: 165px;
    padding: 78px 8px;
  }
  @media (min-width: 620px) {
    max-width: 180px;
    max-height: 180px;
    padding: 80px 8px;
  }
  @media (max-width: 345px) {
    max-width: 90px;
    max-height: 90px;
  }
`;

export const PopupButton = styled.div`
  cursor: pointer;
}
`;

export const PortfolioTitle = styled.p`
  color: #4d4d4d;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  width: 100%;
  padding: 2px 0px 0px;
`;

export const TextTitle = styled.p`
  border-bottom: solid 0px #555;
  padding: 0px 0px 4px 0px;
  width: 45px;
  margin: 12px 20px 8px;
  text-align: left;
  color: #4d4d4d;
  font-size: 14px;
  letter-spacing: 0.54px;
`;

export const TextMiniTitle = styled.p`
  text-align: center;
  color: #4d4d4d;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.46px;
  opacity: 1;
`;

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: animation-keyframes 0.2s ease-in-out;
  @keyframes animation-keyframes {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
`;

export const PopupContent = styled.div`
  width: 80%;
  height: 90%;
  max-width: 480px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: white;
  padding: 36px 16px 24px;
  position: relative;
  border-radius: 1px;
}
`;

export const PopupCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px 16px;
  background-color: rgba(0, 0, 0, 0);
  font-size: 42px;
  color: #7d7d7d;
  border: none;
  cursor: pointer;
  font-family: Nunito,sans-serif;
  font-weight: 200;
}
`;

// botton
export const StyledButton = styled.button`
  font-family: sans-serif;
  border: solid 1px rgba(20, 20, 20, 0.7);
  padding: 10px;
  margin-top:12px;
  border-radius: 20px;
  background-color: rgba(50, 50, 50, 0);
  font-weight: 500;
  font-size: 9px;
  color: #333;
  width: 220px;
  cursor: pointer;
  box-shadow: 0px 1px 0px 0px rgba(20, 20, 20, 0.3);
  -webkit-box-shadow: 0px 1px 0px 0px rgba(20, 20, 20, 0.3);
  -moz-box-shadow: 0px 1px 0px 0px rgba(20, 20, 20, 0.3);
  transition: .1s;
  :active {
    transform: translate3d(0, 1px, 0);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    }
`;

// text
export const TextDescription = styled.p`
  color: #4d4d4d;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  font-weight: 500;
`;

// footer
export const CopyWrite = styled.p`
  text-align: center;
  margin: 64px 12px 32px;
  color: #4d4d4d;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.46px;
`;