import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import { Link, Element } from 'react-scroll';


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const [feedback, setFeedback] = useState("");

  const [tokensOfOwner, setTokensOfOwner] = useState([]);
  const [staked, setStaked] = useState([]);
  const [approve, setApprove] = useState(false);
  const [timothy, setTimothy] = useState();
  const [claimableTimothy, setClaimableTimothy] = useState();


  // check
  const checkTokensOfOwner = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const tokenIds = await blockchain.smartContract.methods.getWalletOfOwnerChinchillife(blockchain.account).call();
      setTokensOfOwner(tokenIds);
    }
  };

  const checkApprovedForAll = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const approve = await blockchain.smartContract.methods.getIsApprovedForAll(blockchain.account, stakeContract).call();
      setApprove(approve);
    }
  };

  const checkStakedTokens = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const tokenIds = await blockchain.smartContract.methods.getStakedTokens(blockchain.account).call();
      setStaked(tokenIds);
    }
  };

  const checktimothy = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const timothy = await blockchain.smartContract.methods.timothy(blockchain.account).call();
      setTimothy(timothy);
    }
  };

  const checkClaimableTimothy = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const claimableTimothy = await blockchain.smartContract.methods.checkClaimableTimothy(blockchain.account).call();
      setClaimableTimothy(claimableTimothy);
    }
  };

  // staking ----------
  const stakeContract = "0x6c2c79Aa77ea8680dd2839b888E02aB24d8b89dE";
  const [tokenIDs, setTokenIDs] = useState([]);

  const handleTokenClick = (tokenId) => {
    setFeedback(``);
    if (tokenIDs.includes(tokenId)) {
      setTokenIDs(prevTokenIDs => prevTokenIDs.filter(id => id !== tokenId));
    } else {
      setTokenIDs(prevTokenIDs => [...prevTokenIDs, tokenId]);
    }
  };

  const handleStakeButtonClick = () => {
    stake(tokenIDs);
  };

  const stake = async (tokenIDs) => {
    let method = await blockchain.smartContract.methods.stake(tokenIDs);
    setFeedback(`散歩の準備中です...`);
    try {
      let receipt = await method.send({
        to: stakeContract,
        from: blockchain.account,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedback(
        `散歩に出かけました！`
      );
      setTokenIDs([]);
      checkTokensOfOwner();
      checkStakedTokens();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
    }
  };

  const [unstakeTokenIDs, setUnstakeTokenIDs] = useState([]);

  const handleTokenClickUnstake = (tokenId) => {
    setFeedback(``);
    if (unstakeTokenIDs.includes(tokenId)) {
      setUnstakeTokenIDs(prevTokenIDs => prevTokenIDs.filter(id => id !== tokenId));
    } else {
      setUnstakeTokenIDs(prevTokenIDs => [...prevTokenIDs, tokenId]);
    }
  };

  const handleStakeButtonClickUnstake = () => {
    unstake(unstakeTokenIDs);
  };

  const unstake = async (unstakeTokenIDs) => {
    let method = await blockchain.smartContract.methods.unstake(unstakeTokenIDs);
    setFeedback(`帰宅中です...`);
    try {
      let receipt = await method.send({
        to: stakeContract,
        from: blockchain.account,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedback(
        `帰宅しました！`
      );
      setUnstakeTokenIDs([]);
      checkTokensOfOwner();
      checkStakedTokens();
      checktimothy();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
    }
  };

  const claim = async () => {
    let method = await blockchain.smartContract.methods.claimTimothy();
    setFeedback(`収穫中です...`);
    try {
      let receipt = await method.send({
        to: stakeContract,
        from: blockchain.account,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      checktimothy();
      checkClaimableTimothy();
      dispatch(fetchData(blockchain.account));
      setFeedback(
        `収穫しました！`
      );
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
    }
  };


  // pop up
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPopupData, setCurrentPopupData] = useState({});

  const openPopup = (data) => {
    setIsPopupOpen(true);
    setCurrentPopupData(data);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentPopupData({});
  };

  const portfolioDataSelf = [

  ]

  const portfolioData = [
    {
      title: "EXODUS",
      description: '「中央集権からの集団脱出」をテーマに構成されたコミュニティ。アートや音楽、メタバースの制作がストーリーと交差しながら進んでいく。運営兼エンジニア兼ダンサーとして所属しています。',
      imageSrc: '/config/images/1.jpg',
      linkTitle1: "EXODUS オフィシャルサイト",
      specification1: "EXODUSのオフィシャルサイトを制作。主にストーリーの掲載やアートの展示、キャラクターの解説をしています。",
      link1: "https://exodus-ffw.xyz",
      linkTitle2: "EXODUS 1st generative(OpenSea)",
      specification2: "ジェネラティブアートNFTの販売用にスマートコントラクト開発を担当。複数のALや販売価格、リビールに対応。複数のトークンを同時にミントする際ののガス代を抑えられるERC721Psiを採用しました。",
      link2: "https://opensea.io/collection/exodus-1st",
      linkTitle3: "EXODUS ミントサイト",
      specification3: "アートを活かすことを意識してシンプルなデザインになるように意識してミントサイトを制作しました。",
      link3: "https://exodus-mintsite.pages.dev/",
      linkTitle4: "Exodus Record(OpenSea)",
      specification4: "音楽NFTの販売用にマートコントラクト開発を開発。トークン毎にSBTの設定や価格の変更に対応したSFT。ERC1155を採用しています。",
      link4: "https://opensea.io/collection/exodus-record",
    },
    {
      title: "とれたつファーム",
      description: '週1回45分だけ配信されるNFT情報番組。パーソナリティを務めながら、webサイトやスマートコントラクトの制作を担当しています。',
      imageSrc: '/config/images/2.jpg',
      linkTitle1: "とれたつファームwebサイト",
      specification1: "番組のオフィシャルwebサイト制作。サイト内ではとれたつクーポンを使用して遊ぶことが出来て、Metamask経由でウォレットと接続し保有トークンの確認や操作が可能です。",
      link1: "https://toretatu-farm.vercel.app",
      linkTitle2: "とれたつクーポン(OpenSea)",
      specification2: "トークンをレベルアップさせるための抽選機能や、レベル毎にメタデータを変更(イラストやプロパティが変化)する機能を搭載したスマートコントラクトを開発。ERC721Burnableを採用しSBTとして発行しています。",
      link2: "https://opensea.io/collection/toretatu-coupon",
    },
    {
      title: "NEW AGE",
      description: '日本を代表するNFTコレクション「NEO TOKYO PUNKS」の幼少期からの成長を観察出来るコレクション。エンジニアとして参加させていただきました。',
      imageSrc: '/config/images/21.jpg',
      linkTitle1: "TBA生成サイト",
      specification1: "スマートコントラクト開発およびTBA(Token bound Account)を生成するサイトの制作を担当させていただきました。ERC6551を使用し、保有している各NTPに紐付くアカウントを生成することが可能です。",
      link1: "https://ntp-tba-create.vercel.app/",
      linkTitle2: "NEW AGE クレイムサイト",
      specification2: "スマートコントラクト開発およびNEW AGE(成長観察SBT)のクレイムサイトの制作を担当させていただきました。ERC6551で生成された、各NTPに紐付くTBAに直接SBTをクレイムすることが可能です。",
      link2: "https://new-age-claim.vercel.app/",
      linkTitle3: "NEW AGE チェッカー",
      specification3: "各NTPが保有するNEW AGE(成長観察SBT)を確認するサイトの制作を担当させていただきました。",
      link3: "https://new-age-checker.vercel.app/",
    },
    {
      title: "KDDI×PARDEY NFTを活用した地域共創の実証実験",
      description: '「KDDI」様と「PARDEY」様が行ったNFTを活用した地域共創の実証実験。第一弾は広島県のクリエイターや事業者が参加される中エンジニアとして参加させていただきました。',
      imageSrc: '/config/images/13.jpg',
      linkTitle1: "NFTを活用した地域応援のための実証実験webサイト",
      specification1: "スマートコントラクト開発およびミントサイトの制作を担当させていただきました。ERC1155(SFT)を採用。3種類の作品の中からランダムにミントされる機能を実装しています。",
      link1: "https://regional-support-test.pages.dev/"
    },
    {
      title: "百鬼夜行",
      description: 'web3コミュニティ「新世界DAO」からローンチされたジェネラティブコレクション。エンジニアとして参加させていただきました。',
      imageSrc: '/config/images/20.jpg',
      linkTitle1: "百鬼夜行 ミントサイト",
      specification1: "スマートコントラクト開発およびミントサイトの制作を担当させていただきました。アドレスごとにフリーミントの枚数を管理。サイトから直接ERC6551で生成されたTBAにNFTを送ることが出来る仕様で制作しました。",
      link1: "https://hyakki-generative-prod.web.app/",
    },
    {
      title: "MOKUMOKU",
      description: 'サウナ&シーシャ×地方創生を実現する会員制NFTプロジェクト。エンジニアとして参加させていただきました。',
      imageSrc: '/config/images/15.jpg',
      linkTitle1: "MOKUMOKU GENESIS VIP PASS ミントサイト",
      specification1: "スマートコントラクト開発およびミントサイトの制作を担当させていただきました。ERC721Psiを採用し、トークンのレベル管理機能やレベル毎にメタデータを変更(イラストやプロパティが変化)する機能を搭載しています。ミントサイトでは暗号通貨支払いとクレジットカードでの決済も導入しています。",
      link1: "https://mokumoku-mintsite.pages.dev/"
    },
    {
      title: "Covered Village",
      description: '「カバードピープル」発のジェネラティブコレクションとしてリリースされ、3333枚が即日完売。初期からコミュニティに参加しており、その流れからミントサイト制作を担当させていただきました。',
      imageSrc: '/config/images/4.jpg',
      linkTitle1: "Covered Village ミントサイト",
      specification1: "他の方によって開発されたコントラクトを制作したミントサイトに連携。サイトからNFTのミントを可能にしました。",
      link1: "https://coveredvillage.pages.dev/",
      linkTitle2: "カバビレチェッカー",
      specification2: "保有しているカバビレの数と階級をチェックできるサイトです。",
      link2: "https://coveredvillage-checker.vercel.app/"
    },
    {
      title: "カバードピープル×薩摩川内市のふるさと納税",
      description: '薩摩川内市のふるさと納税の返礼品としてカバードピープルコミュニティ内で制作されたNFTを提供。「Covered Village」のミントサイト制作を担当した流れで参加させていただきました。',
      imageSrc: '/config/images/6.jpg',
      linkTitle1: "カバードピープル×薩摩川内市のふるさと納税(OpenSea)",
      specification1: "スマートコントラクトの開発およびNFTの配布作業を担当させていただいています。納税者への返礼品としてNFTを配布。1年間ロック後(SBT)売買可能にする機能を実装しています。ERC721を採用し時限式SBT。送付時のガス代を考慮してPolygonチェーンにて発行しています。",
      link1: "https://opensea.io/collection/coveredhometowntax/",
    },
    {
      title: "grin peace",
      description: '4名の女性クリエイターからなる「YAMA」様のジェネラティブコレクション。エンジニアとして参加させていただきました',
      imageSrc: '/config/images/18.jpg',
      linkTitle1: "grin peace",
      specification1: "スマートコントラクト開発や作品メタデータ生成およびミントサイトの制作を担当させていただきました。複数のALや販売価格、クレカ決済などに対応。ERC721Psiを採用しました。",
      link1: "https://grinpeace.net/"
    },
    {
      title: "1000km NFTマラソン",
      description: 'ブドウちゃん様が1000km走りながら応援SBTを購入してもらうという企画。エンジニア兼サポーターとして参加させていただきました。',
      imageSrc: '/config/images/16.jpg',
      linkTitle1: "1000km NFTマラソン ミントサイト",
      specification1: "スマートコントラクト開発およびミントサイトの制作を担当させていただきました。ERC1155(SFT)を採用しSBTとしてトークンを発行しています。販売期間中はフェーズ毎に最大供給量を増やしながら運用しました。",
      link1: "https://1000km-nft-marathon.pages.dev/"
    },
    {
      title: "グルコースマンi",
      description: 'web3コミュニティ「ごじゃ会」からリリースされたジェネラティブコレクション。エンジニアとして参加させていただきました。',
      imageSrc: '/config/images/19.jpg',
      linkTitle1: "グルカタジェネ ミントサイト",
      specification1: "スマートコントラクト開発およびミントサイトの制作を担当させていただきました。アドレス毎のフリーミント枚数の管理することが可能。クレカ決済や最大供給数の変更に対応。ERC721Psiを採用しました。",
      link1: ""
    },
    {
      title: "Dreamin' Diver Project",
      description: '仮想空間で過ごすダイバーたちを描くプロジェクト。公式ホームページの制作させていただきました。',
      imageSrc: '/config/images/5.jpg',
      linkTitle1: "Dreamin' Diver Project webサイト",
      specification1: "プロジェクトのオフィシャルwebサイト制作を担当させていただきました。",
      link1: "https://dreamindiverspj.com/",
    },
    {
      title: "TUNA BABY",
      description: '「Tuna Fox Crab!」のセカンドコレクションとしてXRPLチェーンでリリース。エンジニアとして参加させていただきました',
      imageSrc: '/config/images/17.jpg',
      linkTitle1: "TUNA BABY(xrp.cafe)",
      specification1: "画像ジェネレートとメタデータの生成を担当させていただきました。マーケットプレイスの仕様に合わせたメタデータを生成しました。",
      link1: "https://xrp.cafe/collection/tuna-baby"
    },
    {
      title: "Covered Records",
      description: '「カバードピープル」の姉妹コレクション。姉妹コレクション初の音楽グループ。ジェネラティブ楽曲「THE DAY」のリリース時及びコントラクト移行時に、技術サポートして参加させていただきました。',
      imageSrc: '/config/images/7.jpg',
      linkTitle1: "Covered Records「THE DAY」",
      specification1: "ERC721を採用しスマートコントラクトを開発し、ミントサイトには試聴機能を実装しました。音楽プラットフォームにも対応出来る形式でメタデータを生成。リリース1年後にPolygonチェーンへ移行済み",
      link1: "https://coveredrecords-theday.pages.dev/"
    },
    {
      title: "ten.draws Thanks NFT",
      description: 'イラストレーターであり「Queens of New World」のファウンダーでもあるten.draws様の特典配布用コレクション。コントラクト制作および作品の配布を担当させていただきました。',
      imageSrc: '/config/images/8.jpg',
      linkTitle1: "ten.draws Thanks NFT(OpenSea)",
      specification1: "ホルダー様向けに特典やお礼として配布するためのスマートコントラクトを開発。作品の配布も担当しています。ERC1155(SFT)を採用し指定アドレスへの一括送付を可能にしています。送付時のガス代を考慮してPolygonチェーンにて発行。",
      link1: "https://opensea.io/collection/tendraws-thanksnft"

    },
    {
      title: "ぐうたらまにあ",
      description: 'イラストレーターもぐ様によるジェネラティブコレクション。サポートメンバーとして参加させていただき、発売時のスペースや特典用コレクションのスマートコントラクト開発させていただきました。',
      imageSrc: '/config/images/9.jpg',
      linkTitle1: "GUTARA Benefits(OpenSea)",
      specification1: "ホルダー様向けに特典やお礼として配布するためのスマートコントラクトを開発。作品の配布も担当しています。ERC1155を採用し指定アドレスへの一括送付を可能にしています。送付時のガス代を考慮してPolygonチェーンにて発行。",
      link1: "https://opensea.io/collection/gutara-benefits"
    },
    {
      title: "カバード検定準二級",
      description: 'のすけ様が提供するサービス。カバードピープルについてのクイズを楽しめます。満点を取ると認定書SBTをミントすることが出来ます。',
      imageSrc: '/config/images/10.jpg',
      linkTitle1: "カバード検定準二級",
      specification1: "スマートコントラクトおよびwebアプリケーションを制作させていただきました。ERC1155(SFT)を採用しパスワードを使用してミントする仕組みを実装。クイズに10問正解するとSBT認定書をミントすることが出来ます。",
      link1: "https://covered-kentei.pages.dev/"
    },
    {
      title: "Tuna Fox Crab!",
      description: '𝕏のスペース「わおぽけラジオ」内で制作された犬のジェネラティブコレクション。発案から制作、販売、ミントアウトまでが半日という即席コレクション。',
      imageSrc: '/config/images/11.jpg',
      linkTitle1: "Tuna Fox Crab!(OpenSea)",
      specification1: "スマートコントラクト開発およびミントサイトの制作を担当させていただきました。イラストジェネラティブコレクションとしてリリース後、音楽や新たな属性の追加、イラストの変化と進化し続けるコレクション。ERC721を採用しPolygonチェーンにて発行。操作時のガス代を安価に済ませることを可能にしています。",
      link1: "https://opensea.io/collection/tuna-fox-crab",
    },
    {
      title: "舞踏と蝶のシンフォニア/流麗な蝶のシンフォニア",
      description: '音楽NFTプラットフォーム「SoundDesert」からリリースされた音楽ジェネラティブコレクション。エンジニアとして参加させていただきました。',
      imageSrc: '/config/images/12.jpg',
      linkTitle1: "舞踏と蝶のシンフォニア/流麗な蝶のシンフォニア",
      specification1: "SoundDesert様と連携し、スマートコントラクト開発およびミントサイトの制作を担当させていただきました。コントラクトはERC721を採用。プラットフォームにも対応出来る形式でメタデータを生成しました。",
      link1: "https://butou-ryurei.pages.dev/"
    },
    {
      title: "王子に恩を売れるNFT",
      description: 'スペースパーソナリティであり、NFTクリエイターとして活動するNFT王子様に恩を売れるだけのNFT。エンジニアとして制作に携わらせていただきました。',
      imageSrc: '/config/images/14.jpg',
      linkTitle1: "王子に恩を売れるNFT",
      specification1: "ERC1155(SFT)を採用。トークン毎にSBTの設定や価格の変更に対応出来るようにしています。全種類のトークンを購入出来る仕様でミントサイトを構築しました。",
      link1: "https://ouji-nft.pages.dev/"
    },
    {
      title: "8bit covered people",
      description: '「カバードピープル」公式姉妹コレクションとしてリリースされた動くピクセルアートのコレクション。米ジルシさんと共同運営しています。',
      imageSrc: '/config/images/3.jpg',
      linkTitle1: "8bit covered people(OpenSea)",
      specification1: "広報と初期ロットの作品ベース制作を担当しています。ピクセルアートクリエイターとしての経験を活かして制作しました。",
      link1: "https://opensea.io/collection/8bit-covered-people",
    },
  ];

  const portfolioDataCollection = [
    {
      title: "Chinchilland",
      description: 'いろんな役割を演じて遊ぶチンチラのコレクション。活動初期からOpenSea共用コントラクトで運用。OpenSeaの仕様変更に伴い完結&完売。',
      imageSrc: '/config/images/land.png',
      linkTitle1: "Chinchilland (OpenSea)",
      link1: "https://opensea.io/collection/chinchilland"
    },
    {
      title: "Chinchillife",
      description: '一緒に住んでいるチンチラのレタスの日常を描いた、全98作品のコレクション。レタスと出会った記念日に制作開始を発表。自身初のジェネラティブとして独自コントラクトでリリースし、フリーミント期間中にミントアウト。コントラクトの差し替えを経験した後にステーキング機能などを実装。',
      imageSrc: '/config/images/32.png',
      linkTitle1: "Chinchillife (OpenSea)",
      link1: "https://opensea.io/collection/chinchillife-v2"
    },
    {
      title: "CHINCHILL",
      description: 'くつろいでいるチンチラのgifアニメーション作品。XRPLedger上に発行し、現在も制作&販売中。全51作品で完結予定。',
      imageSrc: '/config/images/chill.png',
      linkTitle1: "CHINCHILL (xrp.cafe)",
      link1: "https://xrp.cafe/collection/chinchill",
    },
    {
      title: "FullOnChainLettuce",
      description: '一緒に住んでいるチンチラのレタスの4歳を記念して、BaseChain(フルオンチェーン)で51枚発行。',
      imageSrc: '/config/images/full.png',
      linkTitle1: "FullOnChainLettuce (OpenSea)",
      link1: "https://opensea.io/collection/fullonchainlettuce",
    }
  ]

  useEffect(() => {
    checkTokensOfOwner();
    checkApprovedForAll();
    checkStakedTokens();
    checktimothy();
    checkClaimableTimothy();
  }, []);

  useEffect(() => {
    checkTokensOfOwner();
    checkApprovedForAll();
    checkStakedTokens();
    checktimothy();
    checkClaimableTimothy();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.NavBar>
        <s.Logo>れたす</s.Logo>
      </s.NavBar>
      <s.TextTitle style={{ marginTop: "48px" }}>Profile</s.TextTitle>
      <s.ProfileArea>
        NFT制作に特化したエンジニア。スマートコントラクトの開発からミントサイトの構築まで承っております。自身でもチンチラをモデルにしたドット絵のNFTを制作しています。
      </s.ProfileArea>
      <s.ProfileArea style={{ paddingTop: "8px" }}>
        クリエイティブプロジェクト『<a href="https://exodus-ffw.xyz/" target="_blank" rel="noopener" style={{ color: "#0066c0", textDecoration: "none", fontWeight: "600" }}>EXODUS</a>』運営
      </s.ProfileArea>
      <s.ProfileArea style={{}}>
        ラジオ番組『<a href="https://toretatu-farm.vercel.app" target="_blank" rel="noopener" style={{ color: "#0066c0", textDecoration: "none", fontWeight: "600" }}>とれたつファーム</a>』パーソナリティ
      </s.ProfileArea>
      <s.ProfileArea style={{ paddingTop: "4px", textAlign: "right", fontSize: "11px" }}>
        お問い合わせ → <a href="https://twitter.com/lettuce908" target="_blank" rel="noopener" style={{ color: "#0066c0", textDecoration: "none" }}><img style={{ maxWidth: "9px" }} src="/config/images/x-logo.png" alt="X" /></a>
      </s.ProfileArea>
      <div style={{ width: '100%', overflowX: 'auto', padding: '0px' }}>
        {portfolioDataSelf.map((item, index) => (
          <PopupButton onClick={() => openPopup(item)}>
            <PortfolioCard ref={ref} className={isVisible ? 'visible' : ''}>
              <img style={{ padding: "3px", maxWidth: '156px' }} src={item.imageSrc} alt={item.title} />
              <PortfolioTitle>
                {item.title}
              </PortfolioTitle>
            </PortfolioCard>
          </PopupButton>
        ))}
        {isPopupOpen && (
          <s.PopupOverlay onClick={closePopup}>
            <s.PopupContent onClick={(e) => e.stopPropagation()}>
              <s.TextMiniTitle style={{ margin: "8px", fontWeight: "600", fontSize: "14px" }}>{currentPopupData.title}</s.TextMiniTitle>
              <s.TextDescription style={{}}><img style={{ width: "100%", height: "100%", maxWidth: "480px" }} src={currentPopupData.imageSrc} alt={currentPopupData.title} /></s.TextDescription>
              <s.TextDescription style={{ margin: "6px 16px 12px" }}>{currentPopupData.description}</s.TextDescription>

              <s.TextDescription><a href={currentPopupData.link1} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle1}</a></s.TextDescription>
              <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification1}</s.TextDescription>

              <s.TextDescription><a href={currentPopupData.link2} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle2}</a></s.TextDescription>
              <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification2}</s.TextDescription>

              <s.TextDescription><a href={currentPopupData.link3} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle3}</a></s.TextDescription>
              <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification3}</s.TextDescription>

              <s.TextDescription><a href={currentPopupData.link4} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle4}</a></s.TextDescription>
              <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification4}</s.TextDescription>

              <s.PopupCloseButton onClick={closePopup}>×</s.PopupCloseButton>
            </s.PopupContent>
          </s.PopupOverlay>
        )}
      </div>

      <div style={{ textAlign: 'center', maxWidth: '100%', borderTop: "0px solid rgba(192, 192, 192, 0.3)", backgroundColor: "rgba(220, 220, 220, 0)" }}>
        <s.TextTitle style={{ paddingTop: "32px" }}>Works</s.TextTitle>
        <div style={{ width: '100%', overflowX: 'auto', padding: '0px 12px' }}>
          <s.ResponsiveWrapper style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {portfolioData.map((item, index) => (
              <s.PopupButton key={index} onClick={() => openPopup(item)}>
                <s.PortfolioCard
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div style={{ position: 'relative' }}>
                    <img
                      style={{
                        maxWidth: '100%',
                        transition: "filter 0.3s ease",
                        filter: index === hoveredIndex ? "brightness(0.2)" : "brightness(1)"
                      }}
                      src={item.imageSrc}
                      alt={item.title}
                    />
                    {index === hoveredIndex && (
                      <s.CardHover>{item.title}</s.CardHover>
                    )}
                  </div>
                </s.PortfolioCard>
              </s.PopupButton>
            ))}

            {isPopupOpen && (
              <s.PopupOverlay onClick={closePopup}>
                <s.PopupContent onClick={(e) => e.stopPropagation()}>
                  <s.TextMiniTitle style={{ margin: "8px", fontWeight: "600", fontSize: "14px" }}>{currentPopupData.title}</s.TextMiniTitle>
                  <s.TextDescription style={{}}><img style={{ width: "95%", height: "95%", maxWidth: "480px" }} src={currentPopupData.imageSrc} alt={currentPopupData.title} /></s.TextDescription>
                  <s.TextDescription style={{ margin: "6px 16px 12px" }}>{currentPopupData.description}</s.TextDescription>

                  <s.TextDescription><a href={currentPopupData.link1} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle1}</a></s.TextDescription>
                  <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification1}</s.TextDescription>

                  <s.TextDescription><a href={currentPopupData.link2} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle2}</a></s.TextDescription>
                  <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification2}</s.TextDescription>

                  <s.TextDescription><a href={currentPopupData.link3} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle3}</a></s.TextDescription>
                  <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification3}</s.TextDescription>

                  <s.TextDescription><a href={currentPopupData.link4} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle4}</a></s.TextDescription>
                  <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification4}</s.TextDescription>

                  <s.PopupCloseButton onClick={closePopup}>×</s.PopupCloseButton>
                </s.PopupContent>
              </s.PopupOverlay>
            )}
          </s.ResponsiveWrapper>
        </div>
      </div>

      <s.TextTitle style={{ paddingTop: "48px", width: "72px", margin: "0 auto" }}>Collection</s.TextTitle>
      <div style={{ width: '100%', overflowX: 'auto', padding: '3px 12px 0px' }}>
        <s.ResponsiveWrapper style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {portfolioDataCollection.map((item, index) => (
            <s.PopupButton key={index} onClick={() => openPopup(item)}>
              <s.PortfolioCard>
                <img
                  style={{
                    padding: "1px",
                    maxWidth: '80px',
                    borderRadius: "90%",
                    transition: "filter 0.3s ease",
                    filter: "brightness(1)"
                  }}
                  src={item.imageSrc}
                  alt={item.title}
                  onMouseOver={(e) => e.target.style.filter = "brightness(0.9)"}
                  onMouseOut={(e) => e.target.style.filter = "brightness(1)"}
                />
              </s.PortfolioCard>
            </s.PopupButton>
          ))}
          {isPopupOpen && (
            <s.PopupOverlay onClick={closePopup}>
              <s.PopupContent onClick={(e) => e.stopPropagation()}>
                <s.TextMiniTitle style={{ margin: "8px", fontWeight: "600", fontSize: "14px" }}>{currentPopupData.title}</s.TextMiniTitle>
                <s.TextDescription style={{}}><img style={{ width: "95%", height: "95%", maxWidth: "480px" }} src={currentPopupData.imageSrc} alt={currentPopupData.title} /></s.TextDescription>
                <s.TextDescription style={{ margin: "6px 16px 12px" }}>{currentPopupData.description}</s.TextDescription>

                <s.TextDescription><a href={currentPopupData.link1} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle1}</a></s.TextDescription>
                <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification1}</s.TextDescription>

                <s.TextDescription><a href={currentPopupData.link2} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle2}</a></s.TextDescription>
                <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification2}</s.TextDescription>

                <s.TextDescription><a href={currentPopupData.link3} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle3}</a></s.TextDescription>
                <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification3}</s.TextDescription>

                <s.TextDescription><a href={currentPopupData.link4} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle4}</a></s.TextDescription>
                <s.TextDescription style={{ margin: "0px 16px 8px" }}>{currentPopupData.specification4}</s.TextDescription>

                <s.PopupCloseButton onClick={closePopup}>×</s.PopupCloseButton>
              </s.PopupContent>
            </s.PopupOverlay>
          )}
        </s.ResponsiveWrapper>
      </div>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container ai={"center"} jc={"center"}>
          <s.StyledButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            Chinchillifeのステーキングはこちら
          </s.StyledButton>
          {blockchain.errorMsg !== "" && (
            <s.TextDescription
              style={{
                fontSize: "12px",
                textAlign: "center",
                color: "#333",
              }}
            >
              {blockchain.errorMsg}
            </s.TextDescription>
          )}
        </s.Container>
      ) : (
        <>
          <s.TextDescription
            style={{
              margin: "24px 0px 10px",
              fontSize: "14px",
              textAlign: "center",
              color: "#333",
              fontWeight: "500",
            }}
          >
            【 保有しているチモシー：{timothy} 】
          </s.TextDescription>
          {tokensOfOwner.length === 0 && staked == 0 ? (
            <s.Container ai={"center"} jc={"center"}>
              <s.TextDescription style={{ fontSize: "12px", textAlign: "center" }}>
                今のところChinchillifeを保有していません<br />よければ
                <a style={{ color: "#0066c0", textDecoration: "none", fontWeight: "500" }} href="https://opensea.io/collection/chinchillife-v2" target="_blank" rel="noopener">
                  コレクションページ
                </a>
                でチンチラたちをご覧ください
              </s.TextDescription>
            </s.Container>
          ) : (
            <>
              <s.TextDescription
                style={{
                  marginTop: "0px",
                  fontSize: "12px",
                  textAlign: "center",
                  color: "#333",
                  fontWeight: "500",
                }}
              >
                - 手持ちのチンチラ -
              </s.TextDescription>
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                {tokensOfOwner.map((tokenId) => (
                  <div key={tokenId} style={{ margin: "8px", padding: "4px", border: tokenIDs.includes(tokenId) ? "2px solid #BFC5CA" : "none", boxSizing: "border-box", borderRadius: "3px" }}>
                    <img
                      style={{ width: "100px", height: "100px", cursor: "pointer" }}
                      src={`/config/images/${tokenId}.png`}
                      alt={`Token ${tokenId}`}
                      onClick={() => handleTokenClick(tokenId)}
                    />
                    <s.TextDescription
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        color: "#333",
                        marginTop: "0px",
                      }}
                    >
                      #{tokenId}
                    </s.TextDescription>
                  </div>
                ))}
              </s.Container>

              {tokensOfOwner.length === 0 && staked != 0 ? (
                <>
                  <s.TextDescription
                    style={{
                      marginTop: "12px",
                      fontSize: "13px",
                      textAlign: "center",
                      color: "#333",
                      fontWeight: "500",
                    }}
                  >
                    全員散歩中です
                  </s.TextDescription>

                </>
              ) : (
                <>
                  {approve ? (
                    <>
                      <s.Container ai={"center"} jc={"center"}>
                        {tokenIDs.length === 0 ? (
                          <>
                            <s.StyledButton onClick={handleStakeButtonClick} disabled={tokenIDs.length === 0}>
                              チンチラを選択してください
                            </s.StyledButton>
                          </>
                        ) : (
                          <>
                            <s.StyledButton onClick={handleStakeButtonClick} disabled={tokenIDs.length === 0}>
                              散歩に出す
                            </s.StyledButton>
                            <s.TextDescription style={{ fontSize: "11px" }}>{feedback}</s.TextDescription>
                          </>
                        )}
                      </s.Container>
                    </>
                  ) : (
                    <>
                      <s.Container ai={"center"} jc={"center"}>
                        <s.StyledButton
                          onClick={() => {
                            window.open(`https://chinchillife-stake-approval.vercel.app/`);
                          }}
                        >
                          散歩を許可する
                        </s.StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.Container ai={"center"} jc={"center"}>
                {staked != 0 ? (
                  <>
                    <s.TextDescription
                      style={{
                        marginTop: "32px",
                        fontSize: "12px",
                        textAlign: "center",
                        color: "#333",
                        fontWeight: "500",
                      }}
                    >
                      - 散歩中のチンチラ -
                    </s.TextDescription>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      {staked.map((tokenId) => (
                        <div key={tokenId} style={{ margin: "8px", padding: "4px", border: unstakeTokenIDs.includes(tokenId) ? "2px solid #BFC5CA" : "none", boxSizing: "border-box", borderRadius: "3px" }}>
                          <img
                            style={{ width: "100px", height: "100px", cursor: "pointer" }}
                            src={`/config/images/${tokenId}.png`}
                            alt={`Token ${tokenId}`}
                            onClick={() => handleTokenClickUnstake(tokenId)}
                          />
                          <s.TextDescription
                            style={{
                              fontSize: "12px",
                              textAlign: "center",
                              color: "#333",
                              marginTop: "0px"
                            }}
                          >
                            #{tokenId}
                          </s.TextDescription>
                        </div>
                      ))}
                    </s.Container>
                    <s.Container ai={"center"} jc={"center"}>
                      {unstakeTokenIDs.length === 0 ? (
                        <>
                          <s.StyledButton style={{ marginTop: "0px"}} onClick={handleStakeButtonClickUnstake} disabled={unstakeTokenIDs.length === 0}>
                            チンチラを選択してください
                          </s.StyledButton>
                          <s.TextDescription style={{ margin: "16px 0px 0px", fontSize: "11px" }}>{claimableTimothy} チモシー収穫可能です</s.TextDescription>
                          <s.StyledButton style={{ margin: "0px 0px 20px" }} onClick={claim} >
                            チモシーを収穫する
                          </s.StyledButton>
                          <s.TextDescription style={{ fontSize: "11px" }}>{feedback}</s.TextDescription>
                        </>
                      ) : (
                        <>
                          <s.StyledButton style={{ marginTop: "0px"}} onClick={handleStakeButtonClickUnstake} disabled={unstakeTokenIDs.length === 0}>
                            帰宅させる
                          </s.StyledButton>
                          <s.TextDescription style={{ margin: "16px 0px 0px", fontSize: "11px" }}>{claimableTimothy} チモシー収穫可能です</s.TextDescription>
                          <s.StyledButton style={{ margin: "0px 0px 20px" }} onClick={claim} >
                            チモシーを収穫する
                          </s.StyledButton>
                          <s.TextDescription style={{ fontSize: "11px" }}>{feedback}</s.TextDescription>
                        </>
                      )}
                    </s.Container>
                  </>
                ) : (
                  <></>
                )}
              </s.Container>
            </>
          )}
        </>
      )}
      <s.CopyWrite>©︎lettuce908</s.CopyWrite>
    </s.Screen>
  );
}
export default App;